/* istanbul ignore next */
namespace BCP47 {
    export const Afar = 'aa' as const
    export type Afar = typeof Afar

    export const Abkhazian = 'ab' as const
    export type Abkhazian = typeof Abkhazian

    export const Avestan = 'ae' as const
    export type Avestan = typeof Avestan

    export const Afrikaans = 'af' as const
    export type Afrikaans = typeof Afrikaans

    export const Akan = 'ak' as const
    export type Akan = typeof Akan

    export const Amharic = 'am' as const
    export type Amharic = typeof Amharic

    export const Aragonese = 'an' as const
    export type Aragonese = typeof Aragonese

    export const Arabic = 'ar' as const
    export type Arabic = typeof Arabic

    export const Assamese = 'as' as const
    export type Assamese = typeof Assamese

    export const Avaric = 'av' as const
    export type Avaric = typeof Avaric

    export const Aymara = 'ay' as const
    export type Aymara = typeof Aymara

    export const Azerbaijani = 'az' as const
    export type Azerbaijani = typeof Azerbaijani

    export const Bashkir = 'ba' as const
    export type Bashkir = typeof Bashkir

    export const Belarusian = 'be' as const
    export type Belarusian = typeof Belarusian

    export const Bulgarian = 'bg' as const
    export type Bulgarian = typeof Bulgarian

    export const Bislama = 'bi' as const
    export type Bislama = typeof Bislama

    export const Bambara = 'bm' as const
    export type Bambara = typeof Bambara

    export const Bengali = 'bn' as const
    export type Bengali = typeof Bengali

    export const Tibetan = 'bo' as const
    export type Tibetan = typeof Tibetan

    export const Breton = 'br' as const
    export type Breton = typeof Breton

    export const Bosnian = 'bs' as const
    export type Bosnian = typeof Bosnian

    export const Catalan = 'ca' as const
    export type Catalan = typeof Catalan

    export const Chechen = 'ce' as const
    export type Chechen = typeof Chechen

    export const Chamorro = 'ch' as const
    export type Chamorro = typeof Chamorro

    export const Corsican = 'co' as const
    export type Corsican = typeof Corsican

    export const Cree = 'cr' as const
    export type Cree = typeof Cree

    export const Czech = 'cs' as const
    export type Czech = typeof Czech

    export const ChurchSlavic = 'cu' as const
    export type ChurchSlavic = typeof ChurchSlavic

    export const Chuvash = 'cv' as const
    export type Chuvash = typeof Chuvash

    export const Welsh = 'cy' as const
    export type Welsh = typeof Welsh

    export const Danish = 'da' as const
    export type Danish = typeof Danish

    export const German = 'de' as const
    export type German = typeof German

    export const Divehi = 'dv' as const
    export type Divehi = typeof Divehi

    export const Dzongkha = 'dz' as const
    export type Dzongkha = typeof Dzongkha

    export const Ewe = 'ee' as const
    export type Ewe = typeof Ewe

    export const Greek = 'el' as const
    export type Greek = typeof Greek

    export const English = 'en' as const
    export type English = typeof English

    export const Esperanto = 'eo' as const
    export type Esperanto = typeof Esperanto

    export const Spanish = 'es' as const
    export type Spanish = typeof Spanish

    export const Estonian = 'et' as const
    export type Estonian = typeof Estonian

    export const Basque = 'eu' as const
    export type Basque = typeof Basque

    export const Persian = 'fa' as const
    export type Persian = typeof Persian

    export const Fulah = 'ff' as const
    export type Fulah = typeof Fulah

    export const Finnish = 'fi' as const
    export type Finnish = typeof Finnish

    export const Fijian = 'fj' as const
    export type Fijian = typeof Fijian

    export const Faroese = 'fo' as const
    export type Faroese = typeof Faroese

    export const French = 'fr' as const
    export type French = typeof French

    export const WesternFrisian = 'fy' as const
    export type WesternFrisian = typeof WesternFrisian

    export const Irish = 'ga' as const
    export type Irish = typeof Irish

    export const Gaelic = 'gd' as const
    export type Gaelic = typeof Gaelic

    export const Galician = 'gl' as const
    export type Galician = typeof Galician

    export const Guarani = 'gn' as const
    export type Guarani = typeof Guarani

    export const Gujarati = 'gu' as const
    export type Gujarati = typeof Gujarati

    export const Manx = 'gv' as const
    export type Manx = typeof Manx

    export const Hausa = 'ha' as const
    export type Hausa = typeof Hausa

    export const Hebrew = 'he' as const
    export type Hebrew = typeof Hebrew

    export const Hindi = 'hi' as const
    export type Hindi = typeof Hindi

    export const HiriMotu = 'ho' as const
    export type HiriMotu = typeof HiriMotu

    export const Croatian = 'hr' as const
    export type Croatian = typeof Croatian

    export const Haitian = 'ht' as const
    export type Haitian = typeof Haitian

    export const Hungarian = 'hu' as const
    export type Hungarian = typeof Hungarian

    export const Armenian = 'hy' as const
    export type Armenian = typeof Armenian

    export const Herero = 'hz' as const
    export type Herero = typeof Herero

    export const Interlingua = 'ia' as const
    export type Interlingua = typeof Interlingua

    export const Indonesian = 'id' as const
    export type Indonesian = typeof Indonesian

    export const Interlingue = 'ie' as const
    export type Interlingue = typeof Interlingue

    export const Igbo = 'ig' as const
    export type Igbo = typeof Igbo

    export const SichuanYi = 'ii' as const
    export type SichuanYi = typeof SichuanYi

    export const Inupiaq = 'ik' as const
    export type Inupiaq = typeof Inupiaq

    export const Ido = 'io' as const
    export type Ido = typeof Ido

    export const Icelandic = 'is' as const
    export type Icelandic = typeof Icelandic

    export const Italian = 'it' as const
    export type Italian = typeof Italian

    export const Inuktitut = 'iu' as const
    export type Inuktitut = typeof Inuktitut

    export const Japanese = 'ja' as const
    export type Japanese = typeof Japanese

    export const Javanese = 'jv' as const
    export type Javanese = typeof Javanese

    export const Georgian = 'ka' as const
    export type Georgian = typeof Georgian

    export const Kongo = 'kg' as const
    export type Kongo = typeof Kongo

    export const Kikuyu = 'ki' as const
    export type Kikuyu = typeof Kikuyu

    export const Kuanyama = 'kj' as const
    export type Kuanyama = typeof Kuanyama

    export const Kazakh = 'kk' as const
    export type Kazakh = typeof Kazakh

    export const Kalaallisut = 'kl' as const
    export type Kalaallisut = typeof Kalaallisut

    export const Khmer = 'km' as const
    export type Khmer = typeof Khmer

    export const Kannada = 'kn' as const
    export type Kannada = typeof Kannada

    export const Korean = 'ko' as const
    export type Korean = typeof Korean

    export const Kanuri = 'kr' as const
    export type Kanuri = typeof Kanuri

    export const Kashmiri = 'ks' as const
    export type Kashmiri = typeof Kashmiri

    export const Kurdish = 'ku' as const
    export type Kurdish = typeof Kurdish

    export const Komi = 'kv' as const
    export type Komi = typeof Komi

    export const Cornish = 'kw' as const
    export type Cornish = typeof Cornish

    export const Kirghiz = 'ky' as const
    export type Kirghiz = typeof Kirghiz

    export const Latin = 'la' as const
    export type Latin = typeof Latin

    export const Luxembourgish = 'lb' as const
    export type Luxembourgish = typeof Luxembourgish

    export const Ganda = 'lg' as const
    export type Ganda = typeof Ganda

    export const Limburgan = 'li' as const
    export type Limburgan = typeof Limburgan

    export const Lingala = 'ln' as const
    export type Lingala = typeof Lingala

    export const Lao = 'lo' as const
    export type Lao = typeof Lao

    export const Lithuanian = 'lt' as const
    export type Lithuanian = typeof Lithuanian

    export const LubaKatanga = 'lu' as const
    export type LubaKatanga = typeof LubaKatanga

    export const Latvian = 'lv' as const
    export type Latvian = typeof Latvian

    export const Malagasy = 'mg' as const
    export type Malagasy = typeof Malagasy

    export const Marshallese = 'mh' as const
    export type Marshallese = typeof Marshallese

    export const Maori = 'mi' as const
    export type Maori = typeof Maori

    export const Macedonian = 'mk' as const
    export type Macedonian = typeof Macedonian

    export const Malayalam = 'ml' as const
    export type Malayalam = typeof Malayalam

    export const Mongolian = 'mn' as const
    export type Mongolian = typeof Mongolian

    export const Marathi = 'mr' as const
    export type Marathi = typeof Marathi

    export const Malay = 'ms' as const
    export type Malay = typeof Malay

    export const Maltese = 'mt' as const
    export type Maltese = typeof Maltese

    export const Burmese = 'my' as const
    export type Burmese = typeof Burmese

    export const Nauru = 'na' as const
    export type Nauru = typeof Nauru

    export const NorwegianBokmal = 'nb' as const
    export type NorwegianBokmal = typeof NorwegianBokmal

    export const NorthNdebele = 'nd' as const
    export type NorthNdebele = typeof NorthNdebele

    export const Nepali = 'ne' as const
    export type Nepali = typeof Nepali

    export const Ndonga = 'ng' as const
    export type Ndonga = typeof Ndonga

    export const Dutch = 'nl' as const
    export type Dutch = typeof Dutch

    export const NorwegianNynorsk = 'nn' as const
    export type NorwegianNynorsk = typeof NorwegianNynorsk
    export const Norwegian = 'no' as const
    export type Norwegian = typeof Norwegian

    export const SouthNdebele = 'nr' as const
    export type SouthNdebele = typeof SouthNdebele

    export const Navajo = 'nv' as const
    export type Navajo = typeof Navajo

    export const Chichewa = 'ny' as const
    export type Chichewa = typeof Chichewa

    export const Occitan = 'oc' as const
    export type Occitan = typeof Occitan

    export const Ojibwa = 'oj' as const
    export type Ojibwa = typeof Ojibwa

    export const Oromo = 'om' as const
    export type Oromo = typeof Oromo

    export const Oriya = 'or' as const
    export type Oriya = typeof Oriya

    export const Ossetian = 'os' as const
    export type Ossetian = typeof Ossetian

    export const Punjabi = 'pa' as const
    export type Punjabi = typeof Punjabi

    export const Pali = 'pi' as const
    export type Pali = typeof Pali

    export const Polish = 'pl' as const
    export type Polish = typeof Polish

    export const Pashto = 'ps' as const
    export type Pashto = typeof Pashto

    export const Portuguese = 'pt' as const
    export type Portuguese = typeof Portuguese

    export const Quechua = 'qu' as const
    export type Quechua = typeof Quechua

    export const Romansh = 'rm' as const
    export type Romansh = typeof Romansh

    export const Rundi = 'rn' as const
    export type Rundi = typeof Rundi

    export const Romanian = 'ro' as const
    export type Romanian = typeof Romanian

    export const Russian = 'ru' as const
    export type Russian = typeof Russian

    export const Kinyarwanda = 'rw' as const
    export type Kinyarwanda = typeof Kinyarwanda

    export const Sanskrit = 'sa' as const
    export type Sanskrit = typeof Sanskrit

    export const Sardinian = 'sc' as const
    export type Sardinian = typeof Sardinian

    export const Sindhi = 'sd' as const
    export type Sindhi = typeof Sindhi

    export const NorthernSami = 'se' as const
    export type NorthernSami = typeof NorthernSami

    export const Sango = 'sg' as const
    export type Sango = typeof Sango

    export const Sinhala = 'si' as const
    export type Sinhala = typeof Sinhala

    export const Slovak = 'sk' as const
    export type Slovak = typeof Slovak

    export const Slovenian = 'sl' as const
    export type Slovenian = typeof Slovenian

    export const Samoan = 'sm' as const
    export type Samoan = typeof Samoan

    export const Shona = 'sn' as const
    export type Shona = typeof Shona

    export const Somali = 'so' as const
    export type Somali = typeof Somali

    export const Albanian = 'sq' as const
    export type Albanian = typeof Albanian

    export const Serbian = 'sr' as const
    export type Serbian = typeof Serbian

    export const Swati = 'ss' as const
    export type Swati = typeof Swati

    export const SouthernSotho = 'st' as const
    export type SouthernSotho = typeof SouthernSotho

    export const Sundanese = 'su' as const
    export type Sundanese = typeof Sundanese

    export const Swedish = 'sv' as const
    export type Swedish = typeof Swedish

    export const Swahili = 'sw' as const
    export type Swahili = typeof Swahili

    export const Tamil = 'ta' as const
    export type Tamil = typeof Tamil

    export const Telugu = 'te' as const
    export type Telugu = typeof Telugu

    export const Tajik = 'tg' as const
    export type Tajik = typeof Tajik

    export const Thai = 'th' as const
    export type Thai = typeof Thai

    export const Tigrinya = 'ti' as const
    export type Tigrinya = typeof Tigrinya

    export const Turkmen = 'tk' as const
    export type Turkmen = typeof Turkmen

    export const Tagalog = 'tl' as const
    export type Tagalog = typeof Tagalog

    export const Tswana = 'tn' as const
    export type Tswana = typeof Tswana

    export const Tonga = 'to' as const
    export type Tonga = typeof Tonga

    export const Turkish = 'tr' as const
    export type Turkish = typeof Turkish
    export const Tsonga = 'ts' as const
    export type Tsonga = typeof Tsonga

    export const Tatar = 'tt' as const
    export type Tatar = typeof Tatar

    export const Twi = 'tw' as const
    export type Twi = typeof Twi

    export const Tahitian = 'ty' as const
    export type Tahitian = typeof Tahitian

    export const Uighur = 'ug' as const
    export type Uighur = typeof Uighur

    export const Ukrainian = 'uk' as const
    export type Ukrainian = typeof Ukrainian

    export const Urdu = 'ur' as const
    export type Urdu = typeof Urdu

    export const Uzbek = 'uz' as const
    export type Uzbek = typeof Uzbek

    export const Venda = 've' as const
    export type Venda = typeof Venda

    export const Vietnamese = 'vi' as const
    export type Vietnamese = typeof Vietnamese

    export const Volapuk = 'vo' as const
    export type Volapuk = typeof Volapuk

    export const Walloon = 'wa' as const
    export type Walloon = typeof Walloon

    export const Wolof = 'wo' as const
    export type Wolof = typeof Wolof

    export const Xhosa = 'xh' as const
    export type Xhosa = typeof Xhosa

    export const Yiddish = 'yi' as const
    export type Yiddish = typeof Yiddish

    export const Yoruba = 'yo' as const
    export type Yoruba = typeof Yoruba

    export const Zhuang = 'za' as const
    export type Zhuang = typeof Zhuang

    export const Chinese = 'zh' as const
    export type Chinese = typeof Chinese

    export const Zulu = 'zu' as const
    export type Zulu = typeof Zulu

    export function is(locale: unknown): locale is BCP47 {
        if (typeof locale !== 'string') return false
        return Object.values(BCP47).includes(locale as BCP47)
    }

    export function Native(locale: English): Native.English
    export function Native(locale: French): Native.French
    export function Native(locale: German): Native.German
    export function Native(locale: Greek): Native.Greek
    export function Native(locale: Portuguese): Native.Portuguese
    export function Native(locale: Spanish): Native.Spanish
    export function Native(locale: BCP47): Native | undefined
    export function Native(locale: unknown): undefined
    export function Native(locale: unknown): Native | undefined {
        switch (locale) {
            case English:
                return Native.English

            case French:
                return Native.French

            case German:
                return Native.German

            case Greek:
                return Native.Greek

            case Portuguese:
                return Native.Portuguese

            case Spanish:
                return Native.Spanish

            default:
                return undefined
        }
    }

    /* istanbul ignore next */
    export namespace Native {
        export const English = 'English' as const
        export type English = typeof English

        export const French = 'français' as const
        export type French = typeof French

        export const German = 'Deutsch' as const
        export type German = typeof German

        export const Greek = 'ελληνικά' as const
        export type Greek = typeof Greek

        export const Portuguese = 'português' as const
        export type Portuguese = typeof Portuguese

        export const Spanish = 'español' as const
        export type Spanish = typeof Spanish

        export function is(locale: unknown): locale is Native {
            switch (locale) {
                case English:
                case French:
                case German:
                case Greek:
                case Portuguese:
                case Spanish:
                    return true
                default:
                    return false
            }
        }
    }

    // biome-ignore lint/suspicious/noRedeclare:
    export type Native = (typeof Native)[Exclude<keyof typeof Native, 'is'>]

    /* istanbul ignore next */
    export namespace Translation {
        export namespace English {
            export const English = 'English' as const
            export type English = typeof English

            export const French = 'French' as const
            export type French = typeof French

            export const German = 'German' as const
            export type German = typeof German

            export const Greek = 'Greek' as const
            export type Greek = typeof Greek

            export const Portuguese = 'Portuguese' as const
            export type Portuguese = typeof Portuguese

            export const Spanish = 'Spanish' as const
            export type Spanish = typeof Spanish
        }

        export type English = (typeof English)[keyof typeof English]

        export namespace French {
            export const English = 'anglais' as const
            export type English = typeof English

            export const French = 'français' as const
            export type French = typeof French

            export const German = 'allemand' as const
            export type German = typeof German

            export const Greek = 'grec' as const
            export type Greek = typeof Greek

            export const Portuguese = 'portugais' as const
            export type Portuguese = typeof Portuguese

            export const Spanish = 'espagnol' as const
            export type Spanish = typeof Spanish
        }

        export type French = (typeof French)[keyof typeof French]

        export namespace German {
            export const English = 'Englisch' as const
            export type English = typeof English

            export const French = 'Französisch' as const
            export type French = typeof French

            export const German = 'Deutsch' as const
            export type German = typeof German

            export const Greek = 'Griechisch' as const
            export type Greek = typeof Greek

            export const Portuguese = 'Portugiesisch' as const
            export type Portuguese = typeof Portuguese

            export const Spanish = 'Spanisch' as const
            export type Spanish = typeof Spanish
        }

        export type German = (typeof German)[keyof typeof German]

        export namespace Greek {
            export const English = 'αγγλικά' as const
            export type English = typeof English

            export const French = 'γαλλικά' as const
            export type French = typeof French

            export const German = 'γερμανικό' as const
            export type German = typeof German

            export const Greek = 'ελληνικά' as const
            export type Greek = typeof Greek

            export const Portuguese = 'πορτογαλικά' as const
            export type Portuguese = typeof Portuguese

            export const Spanish = 'ισπανικά' as const
            export type Spanish = typeof Spanish
        }

        export type Greek = (typeof Greek)[keyof typeof Greek]

        export namespace Portuguese {
            export const English = 'inglês' as const
            export type English = typeof English

            export const French = 'francês' as const
            export type French = typeof French

            export const German = 'alemão' as const
            export type German = typeof German

            export const Greek = 'grego' as const
            export type Greek = typeof Greek

            export const Portuguese = 'português' as const
            export type Portuguese = typeof Portuguese

            export const Spanish = 'espanhol' as const
            export type Spanish = typeof Spanish
        }

        export type Portuguese = (typeof Portuguese)[keyof typeof Portuguese]

        export namespace Spanish {
            export const English = 'inglés' as const
            export type English = typeof English

            export const French = 'francés' as const
            export type French = typeof French

            export const German = 'alemán' as const
            export type German = typeof German

            export const Greek = 'griego' as const
            export type Greek = typeof Greek

            export const Portuguese = 'portugués' as const
            export type Portuguese = typeof Portuguese

            export const Spanish = 'español' as const
            export type Spanish = typeof Spanish
        }

        export type Spanish = (typeof Portuguese)[keyof typeof Spanish]
    }

    export type Translation = (typeof Translation)[keyof typeof Translation]
}

type BCP47 = (typeof BCP47)[Exclude<keyof typeof BCP47, 'is' | 'Native' | 'Translation'>]

export const { Native } = BCP47
export type Native = BCP47.Native

export default BCP47

'use client'

import { CardContent, CardDescription, CardFooter } from '@repo/ui/card'
import { cn } from '@repo/ui/lib/utils'
import RootLayout from 'components/layout/root'
import font from 'font'
import StatusMessage from 'components/card/message/status-message'
import { status as StatusCodes } from 'http-status'
import { Button } from '@repo/ui/button'
import { RotateCcwIcon } from 'lucide-react'
import BCP47 from '@repo/types/locale/BCP47'
import { ThemeProvider } from 'next-themes'
import WebVitals from 'components/analytics/web-vitals'
import { TailwindIndicator } from 'components/dev/tailwind-indicator'
import ThemeToggle from 'components/theme/theme-toggle'

export default function GlobalError({
    error: { message, digest, ...error },
    reset,
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    return (
        <html lang={BCP47.English} suppressHydrationWarning>
            <head />

            <body className={cn('min-h-screen bg-background font-sans antialiased', font.className)}>
                <ThemeProvider attribute='class' defaultTheme='system' enableSystem disableTransitionOnChange>
                    <RootLayout>
                        <div className='relative flex min-h-screen flex-col bg-background'>
                            <header className='sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60'>
                                <div className='container flex h-14 max-w-screen-2xl items-center'>
                                    <div className='flex flex-1 items-center justify-between space-x-2 md:justify-end'>
                                        <nav className='flex items-center space-x-2'>
                                            <ThemeToggle variant='ghost' />
                                        </nav>
                                    </div>
                                </div>
                            </header>

                            <main className='flex-1'>
                                <div className='min-h-screen flex justify-center items-start md:items-center p-8'>
                                    <StatusMessage
                                        status={StatusCodes.INTERNAL_SERVER_ERROR}
                                        title={StatusCodes[StatusCodes.INTERNAL_SERVER_ERROR]}
                                    >
                                        <CardContent className='flex flex-col justify-items-center justify-center items-center space-y-4'>
                                            <CardDescription className='text-md'>
                                                {`${StatusCodes[StatusCodes.INTERNAL_SERVER_ERROR]}: ${message || StatusCodes[`${StatusCodes.INTERNAL_SERVER_ERROR}_MESSAGE`]}`}
                                            </CardDescription>

                                            <Button className='w-fit' onClick={reset}>
                                                <RotateCcwIcon className='w-4 h-4' />
                                                {'Try again'}
                                            </Button>
                                        </CardContent>

                                        {digest && (
                                            <CardFooter>
                                                <CardDescription className='text-sm italic'>
                                                    {`#${digest}`}
                                                </CardDescription>
                                            </CardFooter>
                                        )}
                                    </StatusMessage>
                                </div>
                            </main>
                        </div>
                    </RootLayout>

                    <WebVitals />
                    <TailwindIndicator />
                </ThemeProvider>
            </body>
        </html>
    )
}

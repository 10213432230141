'use client'

import { MoonIcon, SunIcon } from 'lucide-react'
import { useTheme } from 'next-themes'
import * as React from 'react'

import { Button, type ButtonProps } from '@repo/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@repo/ui/dropdown-menu'

export default function ThemeToggle({ variant = 'outline' }: Partial<Pick<ButtonProps, 'variant'>>) {
    const { setTheme } = useTheme()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant={variant} size='icon'>
                    <SunIcon className='h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' />
                    <MoonIcon className='absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' />
                    <span className='sr-only'>Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align='end'>
                <DropdownMenuItem
                    onClick={() => {
                        setTheme('light')
                        dispatchEvent(new StorageEvent('storage', { key: 'theme', newValue: 'light' }))
                    }}
                >
                    Light
                </DropdownMenuItem>

                <DropdownMenuItem
                    onClick={() => {
                        setTheme('dark')
                        dispatchEvent(new StorageEvent('storage', { key: 'theme', newValue: 'dark' }))
                    }}
                >
                    Dark
                </DropdownMenuItem>

                <DropdownMenuItem
                    onClick={() => {
                        setTheme('system')
                        dispatchEvent(new StorageEvent('storage', { key: 'theme', newValue: 'system' }))
                    }}
                >
                    System
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

'use client'

import { useReportWebVitals } from 'next/web-vitals'
import { apiFetch } from 'actions/fetch'

export default function WebVitals() {
    return (
        <>
            {
                useReportWebVitals(metric => {
                    if (process.env.NODE_ENV === 'development') console.log(metric)

                    const pathname = '/analytics' as const
                    const body = JSON.stringify(metric)

                    if (navigator.sendBeacon && process.env.NODE_ENV !== 'development') {
                        const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL
                        if (!NEXT_PUBLIC_API_URL) throw new Error('No API URL has been set')
                        const url = new URL(pathname, NEXT_PUBLIC_API_URL)

                        navigator.sendBeacon(url, body)
                    }
                    else apiFetch(pathname, { body, method: 'POST', keepalive: true })
                })
            }
        </>
    )
}

import { JetBrains_Mono } from 'next/font/google'
import { GeistMono } from 'geist/font/mono'

export const jetbrains_mono = JetBrains_Mono({
    subsets: ['latin-ext', 'greek', 'cyrillic-ext'],
    display: 'swap',
    preload: true,
})

export const geist_mono = GeistMono

const font = geist_mono

export default font
